import { COLORS } from "~components/Common/Theme/Colors/index.jsx";

export const BREAKPOINTS = {
  giant: `2200px`,
  "large-desktop": `1600px`,
  desktop: `1340px`,
  "small-desktop": `1260px`,
  "large-tablet": `1025px`,
  tablet: `769px`,
  "small-tablet": `660px`,
  "large-mobile": `500px`,
  mobile: `400px`,
  "small-mobile": `375px`
};

export const breakpoint = (key, bound = `min`) => {
  if (!BREAKPOINTS?.[key]) {
    return `@media `;
  }

  return `@media only screen and (${bound}-width: ${BREAKPOINTS[key]}) `;
};

export const getColor = (color) => {
  if (color?.charAt(0) === `#`) {
    return color;
  }

  return COLORS[color] || null;
};
