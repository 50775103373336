import { graphql, useStaticQuery } from "gatsby";

const useDocuments = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { full_slug: { regex: "/_data/(.*)/" } }) {
        edges {
          node {
            id
            uuid

            name
            path
            position
            slug
            lang
            group_id
            full_slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content)
      }));

  // ---------------------------------------------------------------------------
  // methods

  const getDocumentById = (id) =>
    data.find(
      ({ uuid }) => uuid?.toLowerCase()?.trim() === id?.toLowerCase()?.trim()
    );

  const getFinishMetadata = () =>
    data?.find(({ slug }) => slug === `finish-metadata`);

  // ---------------------------------------------------------------------------
  // api

  return {
    data,
    getDocumentById,
    getFinishMetadata
  };
};

export default useDocuments;
