import { graphql, useStaticQuery } from "gatsby";

const useStockData = () => {
  const { allDirttSharePrice } = useStaticQuery(graphql`
    query StockData {
      allDirttSharePrice {
        nodes {
          symbol
          price
          change
          change_percent
        }
      }
    }
  `);

  const data = allDirttSharePrice.nodes.map((obj) => obj);

  return data;
};

export default useStockData;
