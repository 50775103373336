import { graphql, useStaticQuery } from "gatsby";

const useMaterials = () => {
  const { allMyDirttResource } = useStaticQuery(graphql`
    {
      allMyDirttResource(
        filter: { full_slug: { regex: "/finishes-and-materials/(.*)/" } }
      ) {
        edges {
          node {
            id
            full_slug

            content {
              name
              type
              material_description

              colours {
                name
                grade
                image
              }

              properties {
                name
                label

                properties {
                  name
                  label

                  applications
                  value
                  values {
                    content
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allMyDirttResource?.edges?.[0]
    ? []
    : allMyDirttResource.edges.map(({ node }) => ({
        ...node,
        type_name: node?.content?.type?.replaceAll(`-`, ` `).toLowerCase()
      }));

  // ---------------------------------------------------------------------------
  // methods

  const getMaterialsByType = (type) => {
    if (!data?.[0]) {
      return [];
    }

    const filteredResults = data?.filter(
      ({ node }) => node?.content?.type?.toLowerCase() === type?.toLowerCase()
    );

    return filteredResults.map(({ node }) => ({
      ...node.content,
      full_slug: node.full_slug
    }));
  };

  const getAllMaterialsByAllTypes = (graphqlData) => {
    let materialData = graphqlData;

    if (!materialData?.[0]) {
      if (!data?.[0]) {
        return [];
      }

      materialData = data;
    }

    const results = {};

    const typeKeys = materialData?.map(
      (item) => item?.content?.type?.toLowerCase() || null
    );

    typeKeys.forEach((key) => {
      results[key.toLowerCase()] = materialData?.filter(
        (item) => item?.content?.type?.toLowerCase() === key?.toLowerCase()
      );
    });

    return results;
  };

  // ---------------------------------------------------------------------------
  // api

  return {
    data,
    getAllMaterialsByAllTypes,
    getMaterialsByType
  };
};

export default useMaterials;
