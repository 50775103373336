/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const useGoogleMaps = (apiKey, options) => {
  const loader = new Loader({
    apiKey,
    version: `weekly`,
    libraries: [`places`]
  });

  const ref = useRef(null);

  const [map, setMap] = useState();
  const [google, setGoogle] = useState();

  useEffect(() => {
    if (!loader || !ref) {
      return;
    }

    loader.load().then((ggl) => {
      setGoogle(ggl);
      setMap(new ggl.maps.Map(ref.current, options));
    });
  }, [loader, ref]);

  return [ref, map, google];
};

export default useGoogleMaps;
