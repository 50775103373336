export const capitalizeString = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

export const getRandomIntByRange = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const remToPx = (rem) => {
  if (typeof window === `undefined`) {
    return rem;
  }

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const arraysShareItem = (array1, array2) => {
  for (let i = 0; i < array1.length; i += 1) {
    for (let j = 0; j < array2.length; j += 1) {
      if (array1[i] === array2[j]) {
        return true;
      }
    }
  }

  return false;
};

export const generateComponentId = (component) =>
  component.toLowerCase().replace(`blok`, ``);
