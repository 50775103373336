import { graphql, useStaticQuery } from "gatsby";

const useProducts = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { full_slug: { regex: "/products/(.*)/" } }) {
        edges {
          node {
            id
            uuid

            name
            path
            position
            slug
            lang
            group_id
            full_slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content)
      }));

  // ---------------------------------------------------------------------------
  // methods

  //

  // ---------------------------------------------------------------------------
  // api

  return {
    data
  };
};

export default useProducts;
