/* eslint-disable camelcase */
import { graphql, useStaticQuery } from "gatsby";

// todo: more reliable filter strategy to exclude myDIRTT resources
// allStoryblokEntry(filter: { full_slug: { regex: "/projects/(.*)/" } }) {
const useNews = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { full_slug: { regex: "^/news/.*/" } }) {
        edges {
          node {
            id
            uuid

            name
            path
            position
            slug
            lang
            group_id
            full_slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content)
      }));

  // ---------------------------------------------------------------------------
  // methods

  const getNewsArticles = () => {
    if (!data?.[0]) {
      return [];
    }

    const filteredResults = data?.filter(
      (item) => item?.content?.component?.toLowerCase() === `article`
    );

    return filteredResults;
  };

  return {
    data,
    getNewsArticles
  };
};

export default useNews;
