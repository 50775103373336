/* eslint-disable camelcase */
import { graphql, useStaticQuery } from "gatsby";
import { capitalizeString } from "~utils/helpers";

// todo: more reliable filter strategy to exclude myDIRTT resources
// allStoryblokEntry(filter: { full_slug: { regex: "/projects/(.*)/" } }) {
const useProjects = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(
        filter: {
          full_slug: {
            regex: "^/projects/(healthcare|education|public-sector|workplace)/.*/"
          }
        }
      ) {
        edges {
          node {
            id
            uuid

            name
            path
            position
            slug
            lang
            group_id
            full_slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content),
        industry:
          node?.full_slug?.indexOf(`/`) !== -1
            ? node.full_slug.split(`/`)[1]
            : null
      }));

  // ---------------------------------------------------------------------------
  // methods

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  // ---------------------------------------------------------------------------
  // methods - industries

  const buildIndustry = (industry, set) => ({
    name: capitalizeString(industry.replace(`-`, ` `)),
    count: set.filter(
      ({ full_slug }) =>
        full_slug?.indexOf(`/`) !== -1 && industry === full_slug.split(`/`)[1]
    ).length,
    value: industry
  });

  const getIndustries = () => {
    const allIndustries = [];

    if (data?.[0]) {
      data.forEach(({ industry }) => {
        if (!industry) {
          return;
        }

        if (!allIndustries?.includes(industry)) {
          allIndustries.push(industry);
        }
      });
    }

    return allIndustries;
  };

  const getEnrichedIndustries = () =>
    getIndustries()
      .map((industry) => buildIndustry(industry, data))
      .sort(sortByName);

  const getEnrichedIndustriesBySubset = (subset) => {
    const subsetIndustries = [];

    if (subset?.[0]) {
      subset.forEach(({ industry }) => {
        if (!industry) {
          return;
        }

        if (!subsetIndustries?.includes(industry)) {
          subsetIndustries.push(industry);
        }
      });
    }

    return subsetIndustries
      .map((industry) => buildIndustry(industry, subset))
      .sort(sortByName);
  };

  // ---------------------------------------------------------------------------
  // methods - tags

  const buildTag = (tag, set) => ({
    name: tag,
    count: set.filter(({ content }) => content?.tags?.includes(tag)).length,
    value: tag
  });

  const getTags = () => {
    const allTags = [];

    if (data?.[0]) {
      data.forEach(({ content }) => {
        const { tags } = content;

        if (!tags?.[0]) {
          return;
        }

        tags.forEach((tag) => {
          if (!allTags?.includes(tag)) {
            allTags.push(tag);
          }
        });
      });
    }

    return allTags;
  };

  const getEnrichedTags = () =>
    getTags()
      .map((tag) => buildTag(tag, data))
      .sort(sortByName);

  const getEnrichedTagsBySubset = (subset) => {
    const subsetTags = [];

    if (subset?.[0]) {
      subset.forEach(({ content }) => {
        const { tags } = content;

        if (!tags?.[0]) {
          return;
        }

        tags.forEach((tag) => {
          if (!subsetTags?.includes(tag)) {
            subsetTags.push(tag);
          }
        });
      });
    }

    return subsetTags.map((tag) => buildTag(tag, subset)).sort(sortByName);
  };

  // ---------------------------------------------------------------------------
  // api

  return {
    data,
    getIndustries,
    getEnrichedIndustries,
    getEnrichedIndustriesBySubset,
    getTags,
    getEnrichedTags,
    getEnrichedTagsBySubset
  };
};

export default useProjects;
