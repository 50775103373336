import { useContext } from "react";
import { NewsletterContext } from "~context/NewsletterContext/index.jsx";

export const useNewsletter = () => {
  const newsletter = useContext(NewsletterContext);

  return newsletter;
};

export default useNewsletter;
