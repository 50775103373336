import { graphql, useStaticQuery } from "gatsby";

const usePartnerLocations = () => {
  const { allPartnerLocation } = useStaticQuery(graphql`
    {
      allPartnerLocation {
        edges {
          node {
            name
            formatted_address
            image
            website

            address_components {
              short_name
              long_name
            }

            geometry {
              location {
                lat
                lng
              }
            }
          }
        }
      }
    }
  `);

  return {
    data: allPartnerLocation.edges.map(({ node }) => node)
  };
};

export default usePartnerLocations;
