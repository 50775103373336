import { graphql, useStaticQuery } from "gatsby";

const useInvestors = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    query Investors {
      allStoryblokEntry(filter: { full_slug: { regex: "/investors/(.*)/" } }) {
        edges {
          node {
            uuid
            name
            slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content)
      }));

  // ---------------------------------------------------------------------------
  // methods

  // ---------------------------------------------------------------------------
  // api

  return data;
};

export default useInvestors;
