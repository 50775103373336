import React from "react";
import { css, Global } from "@emotion/react";

export const COLORS = {
  transparent: `transparent`,
  white: `#FFFFFF`,
  black: `#000000`,
  //
  "mono-50": `#242424`,
  "mono-40": `#2C2C2C`,
  "mono-30": `#343434`,
  "mono-20": `#3C3C3C`,
  "mono-10": `#464646`,
  //
  "grey-70": `#1E1E1E`,
  "grey-60": `#323232`,
  "grey-50": `#4F4F4F`,
  "grey-40": `#969696`,
  "grey-30": `#BEBEBE`,
  "grey-20": `#DDDEE2`,
  "grey-10": `#F0F0F0`,
  //
  "primary-orange-100": `#FF501E`,
  "primary-orange-20": `#FF9678`,
  "primary-orange-10": `#FFCBBC`,
  "primary-blue-100": `#3240BE`,
  "primary-blue-40": `#ADB3E5`,
  "primary-blue-20": `#D6D9F2`,
  "primary-blue-10": `#C1C6EC`,
  //
  "primary-sienna-100": `#A05306`,
  "primary-sienna-20": `#C6986A`,
  "primary-sienna-10": `#E3CBB4`
};

const Colors = () => (
  <Global
    styles={[
      css`
        :root {
          ${Object.keys(COLORS).map(
            (colorKey) => `
              --color-${colorKey}: ${COLORS[colorKey]};
            `
          )}
        }
      `
    ]}
  />
);

export default Colors;
