import { graphql, useStaticQuery } from "gatsby";

const useCollections = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(
        filter: { full_slug: { regex: "/collections/(.*)/" } }
      ) {
        edges {
          node {
            id
            uuid
            name
            path
            position
            slug
            lang
            group_id
            full_slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // methods

  const getProductsByCollection = (collectionId, products) => ({});

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges
        .filter(({ node }) => !node?.full_slug?.includes(`resource`))
        .map(({ node }) => ({
          ...node,
          content: JSON.parse(node.content)
        }));

  // ---------------------------------------------------------------------------
  // api

  return {
    data,
    getProductsByCollection
  };
};

export default useCollections;
