import { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

const useSettings = () => {
  const [settings, setSettings] = useState(null);

  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { full_slug: { regex: "/_settings/(.*)/" } }) {
        edges {
          node {
            slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // methods

  const getHeaderNav = () =>
    settings?.[0] ? settings?.find(({ slug }) => slug === `header-nav`) : null;

  const getSideNav = () =>
    settings?.[0] ? settings?.find(({ slug }) => slug === `side-nav`) : null;

  const getFooterNav = () =>
    settings?.[0] ? settings?.find(({ slug }) => slug === `footer-nav`) : null;

  const getPromoBanner = () =>
    settings?.[0]
      ? settings?.find(({ slug }) => slug === `promo-banner`)
      : null;
  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const data = !allStoryblokEntry?.edges?.[0]
      ? []
      : allStoryblokEntry.edges.map(({ node }) => ({
          ...node,
          content: JSON.parse(node.content)
        }));

    setSettings(data);
  }, [allStoryblokEntry]);

  // ---------------------------------------------------------------------------
  // api

  return {
    settings,
    getHeaderNav,
    getSideNav,
    getFooterNav,
    getPromoBanner
  };
};

export default useSettings;
