import { graphql, useStaticQuery } from "gatsby";

const useMyDirtt = () => {
  const { allMyDirttResource } = useStaticQuery(graphql`
    {
      allMyDirttResource {
        edges {
          node {
            project_id

            content {
              gsa
              _uid
              city
              sq_ft
              region
              status
              address
              country
              summary
              tagline
              duration
              industry
              component
              project_id
              client_name
              issue_sector
              partner_name
              project_name
              issue_caption
              completion_date
              duration_install
              issue_description
              issue_project_name
              name
              type
              keywords
              sku_prefix
              manufacture
              manufacture_url
              material_description
            }
          }
        }
      }
    }
  `);

  const getResourceByProjectId = (projectId) => {
    if (!allMyDirttResource?.edges?.[0]) {
      return;
    }

    return (
      allMyDirttResource?.edges?.find(
        ({ node }) => node?.project_id === projectId
      )?.node?.content || null
    );
  };

  return {
    data: allMyDirttResource.edges.map(({ node }) => node),
    getResourceByProjectId
  };
};

export default useMyDirtt;
