import { graphql, useStaticQuery } from "gatsby";

const useCloudinary = () => {
  const { allCloudinaryResource } = useStaticQuery(graphql`
    {
      allCloudinaryResource {
        edges {
          node {
            public_id
            project_id
            width
            height
            filename
            aspect_ratio
            credit
            caption
            alt

            copyright
            industry
            web_spaces
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allCloudinaryResource?.edges?.[0]
    ? []
    : allCloudinaryResource.edges.map(({ node }) => node);

  // ---------------------------------------------------------------------------
  // methods

  const getAssetsByFolder = (folder) =>
    data.filter((asset) =>
      asset?.public_id?.toLowerCase()?.includes(folder?.toLowerCase())
    );

  const getAssetsByProjectId = (projectId) =>
    data.filter((asset) => asset.project_id === projectId.toString());

  const getImageGalleryAssets = () =>
    data.filter(
      (asset) =>
        asset?.web_spaces?.includes(`DIRTT.com`) && asset.industry !== ``
    );

  const getImageByFilename = (filename) =>
    data.find(
      (asset) =>
        asset?.filename?.toLowerCase()?.trim() === filename.toLowerCase().trim()
    );

  // ---------------------------------------------------------------------------
  // api

  return {
    data,
    getAssetsByFolder,
    getAssetsByProjectId,
    getImageGalleryAssets,
    getImageByFilename
  };
};

export default useCloudinary;
