import { graphql, useStaticQuery } from "gatsby";

const useInsights = () => {
  // ---------------------------------------------------------------------------
  // static data

  const { allStoryblokEntry } = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { full_slug: { regex: "/insights/(.+)/" } }) {
        edges {
          node {
            id
            uuid

            name
            path
            position
            slug
            lang
            group_id
            full_slug
            content
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // variables

  const data = !allStoryblokEntry?.edges?.[0]
    ? []
    : allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content)
      }));

  // ---------------------------------------------------------------------------
  // methods

  const getInsightByID = (id) => data.find(({ uuid }) => uuid === id);

  const getInsightsByIDs = (ids) => {
    if (!ids) {
      return [];
    }

    const insights = data.filter(({ uuid }) => ids.includes(uuid));
    const orderedInsights = insights?.sort(
      (a, b) =>
        (ids.indexOf(a?.uuid) > -1 ? ids.indexOf(a?.uuid) : Infinity) -
        (ids.indexOf(b?.uuid) > -1 ? ids.indexOf(b?.uuid) : Infinity)
    );

    return orderedInsights;
  };

  const getInsightsByType = (type) => {
    if (!type) {
      return [];
    }

    return data
      .filter(({ content: { component } }) => component === type)
      .sort((a, b) => new Date(b.content?.date) - new Date(a.content?.date));
  };

  // ---------------------------------------------------------------------------
  // api

  return {
    data,
    getInsightByID,
    getInsightsByIDs,
    getInsightsByType
  };
};

export default useInsights;
