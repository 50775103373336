import { useEffect } from "react";

const DEFAULT_OPTIONS = {
  attributes: false,
  characterData: false,
  childList: true,
  subtree: false
};

const useMutationObserver = (ref, callback, options = DEFAULT_OPTIONS) => {
  useEffect(() => {
    if (ref?.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);

      return () => observer.disconnect();
    }
  }, [callback, options]);
};

export default useMutationObserver;
